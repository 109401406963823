import React from "react";
import "../assets/css/ExchangeApi.css"; // Assuming you have a CSS file for styles
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faQuestionCircle,
  faHeadset,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const ExchangeApi = () => {
  return (
    <div className="container">
      <div className="header">
        <FontAwesomeIcon icon={faArrowLeft} className="icon" />
        <h1>Exchange API hub</h1>
        <FontAwesomeIcon icon={faQuestionCircle} className="help-icon" />
      </div>
      <div className="banner">Available Exchanges</div>
      <div className="learn-more">Learn how to connect your exchange?</div>
      <div className="exchanges">
        <div className="exchange-card">
          {" "}
          <Link to="/BybitBind">
            <img
              src="https://storage.googleapis.com/a1aa/image/6CvBtMa40IY4JZBO8KMs7JCOGZjLpyGEOb6RZs65pjMHfO6JA.jpg"
              alt="Bybit logo"
            />
            <div>Bybit</div>
            <div className="official-broker">Official Broker</div>
          </Link>
        </div>
        {/* <div className="exchange-card">
          <img
            src="https://storage.googleapis.com/a1aa/image/XNzTHBpgea32KS2w8z342RamybeJ1OoRkpTbEe7lehx6x3RPB.jpg"
            alt="Bybit logo"
          />
          <div className="official-broker">Bybit</div>
        </div> */}
      </div>
      <div className="floating-button">
        <FontAwesomeIcon icon={faHeadset} />
      </div>
    </div>
  );
};

export default ExchangeApi;
