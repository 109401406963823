import React, { useState } from "react";
import "../assets/css/BybitBind.css";
// You should install axios for making HTTP requests
import axios from "axios";

function BybitBind() {
  const [isExpanded, setIsExpanded] = useState(true);
  const [apiName, setApiName] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [riskNoticeChecked, setRiskNoticeChecked] = useState(false);
  const [message, setMessage] = useState("");

  // Toggles the display of the steps
  const toggleSteps = () => {
    setIsExpanded(!isExpanded);
  };

  // Handles the API call to bind the Bybit account
  const handleSubmit = async () => {
    if (!apiName || !apiKey || !secretKey) {
      setMessage("Please fill in all required fields.");
      return;
    }
    if (!riskNoticeChecked) {
      setMessage("Please accept the risk notice.");
      return;
    }

    try {
      // Replace this URL with your actual backend API endpoint for binding Bybit API
      const response = await axios.post("/api/bybit/bind", {
        apiName,
        apiKey,
        secretKey,
      });

      if (response.data.success) {
        setMessage("Bybit API successfully connected.");
      } else {
        setMessage(
          "Failed to connect Bybit API. Please check your credentials."
        );
      }
    } catch (error) {
      setMessage("An error occurred while connecting to Bybit.");
    }
  };

  return (
    <div className="container">
      <div className="header">
        {/* <i className="fas fa-arrow-left back-button"></i> */}
        <h1>Bybit Bind</h1>
      </div>
      <div className="content">
        <h2 onClick={toggleSteps}>
          Bybit Binding Process{" "}
          <i className={`fas fa-chevron-${isExpanded ? "up" : "down"}`}></i>
        </h2>
        <ul className={`steps ${isExpanded ? "expanded" : "collapsed"}`}>
          <li>
            <i className="fas fa-circle"></i>
            <p>
              Log in to your Bybit account and navigate to your Account
              Settings. From there, find the API section and select Create New
              Key.
            </p>
          </li>
          <li>
            <i className="fas fa-circle"></i>
            <p>
              Name the API key and paste the whitelisted IP from Untrade into
              the IP Whitelist section. Set the permissions to Read Data and
              Trading, then click Confirm to create the API key.
            </p>
          </li>
          <li>
            <i className="fas fa-circle"></i>
            <p>
              Bybit will generate an API Key and Secret Key. Make sure to copy
              both keys, as you'll need them to connect with our platform.
            </p>
          </li>
          <li>
            <i className="fas fa-circle"></i>
            <p>
              On our platform, enter the API Key and Secret Key into the
              appropriate fields and assign a custom name to the API, such as
              "Bybit_Bot_1", for easier identification.
            </p>
          </li>
          <li>
            <i className="fas fa-circle"></i>
            <p>
              After entering all details, click Proceed to save and verify your
              API. Once the credentials are validated, you'll receive a
              confirmation message indicating that your Bybit API has been
              successfully connected, enabling you to use it for following
              strategies.
            </p>
          </li>
        </ul>
        <h2>Bybit Binding</h2>
        <p>IP Group Binding</p>
        <p>
          For security purpose, Bybit Exchange recommends binding the server IP
          address when creating the API. For users
        </p>
        <div className="form-group">
          <input type="text" value="35.197.156.184" readOnly />
          <span className="copy-button">
            <i className="fas fa-copy"></i>
          </span>
        </div>
        <p>
          Please check below API permission in your Bybit API section to let
          Untrade work properly. If hedge mode is enabled, it will be disabled
          when you connect your exchange to UnTrade.
        </p>
        <div className="form-group">
          <label>
            <i className="fas fa-check"></i> Enable Spot & Margin Trading
          </label>
        </div>
        <div className="form-group">
          <label>
            <i className="fas fa-check"></i> Enable Future
          </label>
        </div>
        <div className="form-group">
          <label htmlFor="api-name">API Name/ Label*</label>
          <input
            type="text"
            id="api-name"
            value={apiName}
            onChange={(e) => setApiName(e.target.value)}
            placeholder="Please enter API Key"
          />
        </div>
        <div className="form-group">
          <label htmlFor="api-key">API Key*</label>
          <input
            type="text"
            id="api-key"
            value={apiKey}
            onChange={(e) => setApiKey(e.target.value)}
            placeholder="Please enter API Key"
          />
        </div>
        <div className="form-group">
          <label htmlFor="secret-key">Secret Key*</label>
          <input
            type="text"
            id="secret-key"
            value={secretKey}
            onChange={(e) => setSecretKey(e.target.value)}
            placeholder="Please enter Secret Key"
          />
        </div>
        <div className="checkbox-group">
          <input
            type="checkbox"
            id="risk-notice"
            checked={riskNoticeChecked}
            onChange={() => setRiskNoticeChecked(!riskNoticeChecked)}
          />
          <label htmlFor="risk-notice">
            I have carefully read the <a href="#">risk notice</a>
          </label>
        </div>
        <button className="proceed-button" onClick={handleSubmit}>
          Proceed
        </button>
        {message && <p className="message">{message}</p>}
      </div>
    </div>
  );
}

export default BybitBind;
