import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "../src/pages/Home";
import Contact from "../src/pages/Contact";
import Portfolio from "./pages/Portfolio";
import Algo from "./pages/Algo";
import Wallet from "./pages/Wallet";
import Profile from "./pages/Profile";
import ExchangeApi from "./pages/ExchangeApi";
import Login from "./components/Login";
import Register from "./components/Register";
import BybitBind from "./pages/BybitBind";
import WalletBalance from "./walletbalance";

const App = () => {
  return (
    <BrowserRouter>
      <Header />
      <main>
        <Routes>
          <Route path="/Home" element={<Home />} />
          {/* <Route path="/" element={<WalletBalance />} /> */}
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/algo" element={<Algo />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/wallet" element={<Wallet />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/ExchangeApi" element={<ExchangeApi />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/Bybitbind" element={<BybitBind />} />
        </Routes>
      </main>
      <Footer />
    </BrowserRouter>
  );
};

export default App;
