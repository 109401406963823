import React, { useState } from "react";
import ReactDOM from "react-dom";
import "../assets/css/Wallet.css";

const Wallet = () => {
  const [filter, setFilter] = useState({ deposit: true, withdraw: true });
  const [showFilter, setShowFilter] = useState(false);
  const [records, setRecords] = useState([
    { type: "Deposit", amount: 100, date: "2023-10-01" },
    { type: "Withdraw", amount: 50, date: "2023-09-25" },
    { type: "Deposit", amount: 200, date: "2023-09-20" },
  ]);

  const filteredRecords = records.filter(
    (record) =>
      (filter.deposit && record.type === "Deposit") ||
      (filter.withdraw && record.type === "Withdraw")
  );

  const toggleFilter = (type) => {
    setFilter((prevFilter) => ({ ...prevFilter, [type]: !prevFilter[type] }));
  };

  return (
    <div className="container">
      <h1 className="title">Untrade Assets</h1>
      <div className="card">
        <div className="card-header">
          <div>
            <p className="text-sm">
              Total value (USDT) <i className="fas fa-eye"></i>
            </p>
            <p className="text-3xl font-bold">0 USDT</p>
            <p className="text-sm">= 0.0 USD</p>
            <p className="text-xs">
              Locked in open position: 0.0000 USDT{" "}
              <i className="fas fa-info-circle"></i>
            </p>
          </div>
          <div>
            <img
              src="https://storage.googleapis.com/a1aa/image/O79kwC0dxEZsBtrSgPXS1Jk3BmY0k3vi8LaiEF9hZ3sFaz8E.jpg"
              alt="Illustration of a wallet and coins"
              width="100"
              height="100"
            />
          </div>
        </div>
        <div className="card-content">
          <button className="button">Deposit</button>
          <button className="button">Withdraw</button>
        </div>
      </div>
      <div className="history">
        <div className="history-header">
          <h2 className="text-xl font-bold">History records</h2>
          <button
            className="bg-white text-black p-2 rounded-full"
            onClick={() => setShowFilter(true)}
          >
            <i className="fas fa-filter"></i>
          </button>
        </div>
        <div className="mt-4 text-black">
          {filteredRecords.length > 0 ? (
            filteredRecords.map((record, index) => (
              <div key={index} className="history-record">
                <p className="font-bold">{record.type}</p>
                <p className="text-sm">Amount: {record.amount} USDT</p>
                <p className="text-sm">Date: {record.date}</p>
              </div>
            ))
          ) : (
            <p>No Records</p>
          )}
        </div>
      </div>
      {/* <div className="footer">
        <button className="footer-button">
          <i className="fas fa-home"></i>
          <span>Home</span>
        </button>
        <button className="footer-button">
          <i className="fas fa-chart-bar"></i>
          <span>Portfolio</span>
        </button>
        <button className="footer-button">
          <i className="fas fa-rocket"></i>
          <span>Algo</span>
        </button>
        <button className="footer-button">
          <i className="fas fa-wallet"></i>
          <span>Wallet</span>
        </button>
        <button className="footer-button">
          <i className="fas fa-user"></i>
          <span>Profile</span>
        </button>
      </div> */}
      {/* <button className="floating-button">
        <i className="fas fa-headset"></i>
      </button> */}

      {showFilter && (
        <div className="filter-popup">
          <div className="filter-content">
            <h2 className="filter-title">Filter</h2>
            <div className="filter-option">
              <label>
                <input
                  type="checkbox"
                  checked={filter.deposit}
                  onChange={() => toggleFilter("deposit")}
                />
                Deposit
              </label>
            </div>
            <div className="filter-option">
              <label>
                <input
                  type="checkbox"
                  checked={filter.withdraw}
                  onChange={() => toggleFilter("withdraw")}
                />
                Withdraw
              </label>{" "}
            </div>
            <button
              className="apply-button"
              onClick={() => setShowFilter(false)}
            >
              Apply
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Wallet;
