import React, { useState } from "react";
import "../assets/css/Algo.css";

const Algo = () => {
  const [activeTab, setActiveTab] = useState("ROI");

  return (
    <div className="container">
      <div className="header">
        <h1>Bots</h1>
        <div className="tabs">
          <button>Algo</button>
        </div>
        <div className="tab-buttons">
          <button
            className={`tab-button ${activeTab === "ROI" ? "active" : ""}`}
            onClick={() => setActiveTab("ROI")}
          >
            ROI
          </button>
          <button
            className={`tab-button ${activeTab === "AUM" ? "active" : ""}`}
            onClick={() => setActiveTab("AUM")}
          >
            AUM
          </button>
        </div>
      </div>

      {activeTab === "ROI" && (
        <div className="card">
          <div className="flex justify-between items-center">
            <div>
              <h2>Omega - USDM - BTCUSDT - 3X</h2>
              <p>897 Followers</p>
            </div>
            <div className="tags">
              <span>USDM Trading</span>
              <span className="yellow">Leverage - 3x</span>
            </div>
          </div>
          <div className="roi">
            <h3>ROI</h3>
            <div className="roi-values">
              <div className="labels">
                <span>3M</span>
                <span>6M</span>
                <span>1Y</span>
                <span>2Y</span>
                <span>3Y</span>
              </div>
              <div className="values">
                <span>101.03%</span>
                <span>148.91%</span>
                <span>457.19%</span>
                <span>2.20K%</span>
                <span>7.94K%</span>
              </div>
            </div>
          </div>
          <div className="annualized-roi">
            <h3>Annualized ROI</h3>
            <p>+457.19%</p>
          </div>
          <div className="aum">
            <h3>AUM</h3>
            <p>658.32K USDT</p>
          </div>
          <div className="graph">
            <img
              src="https://placehold.co/300x100"
              alt="Graph showing ROI growth over time"
              className="w-full h-24 object-cover"
            />
          </div>
          <div className="follow-button">
            <button>Follow</button>
          </div>
        </div>
      )}

      {activeTab === "AUM" && (
        <div className="card">
          <div className="flex justify-between items-center">
            <div>
              <h2>Omega - COINM- 2X</h2>
              <p>199 Followers</p>
            </div>
            <div className="tags">
              <span>COINM Trading</span>
              <span className="yellow">Leverage - 2x</span>
            </div>
          </div>
          <div className="roi">
            <h3>ROI</h3>
            <div className="roi-values">
              <div className="labels">
                <span>3M</span>
                <span>6M</span>
                <span>1Y</span>
                <span>2Y</span>
                <span>3Y</span>
              </div>
              <div className="values">
                <span>64.27%</span>
                <span>90.24%</span>
                <span>257.10%</span>
                <span>945.98%</span>
                <span>2.50K%</span>
              </div>
            </div>
          </div>
          <div className="annualized-roi">
            <h3>Annualized ROI</h3>
            <p>+257.10%</p>
          </div>
          <div className="aum">
            <h3>AUM</h3>
            <p>0.9991 BTC</p>
          </div>
          <div className="graph">
            <img
              src="https://placehold.co/300x100"
              alt="Graph showing ROI growth over time"
              className="w-full h-24 object-cover"
            />
          </div>
        </div>
      )}

      {/* <div className="bottom-nav">
        <div className="nav-buttons">
          <button className="active">
            <i className="fas fa-home"></i>
            <span>Home</span>
          </button>
          <button>
            <i className="fas fa-chart-line"></i>
            <span>Portfolio</span>
          </button>
          <button>
            <i className="fas fa-robot"></i>
            <span>Algo</span>
          </button>
          <button>
            <i className="fas fa-wallet"></i>
            <span>Wallet</span>
          </button>
          <button>
            <i className="fas fa-user"></i>
            <span>Profile</span>
          </button>
        </div>
      </div> */}
    </div>
  );
};

export default Algo;
