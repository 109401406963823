import React, { useState, useEffect } from "react";
import bnr1 from "../assets/images/bnr1.jpg"; // Import your local images
import bnr2 from "../assets/images/bnr2.jpg";
import bnr3 from "../assets/images/bnr3.jpg";

const Slider = () => {
  const [slideIndex, setSlideIndex] = useState(0);

  const images = [
    {
      src: bnr1,
      alt: "Slide 1",
      text: "Register",
      link: "https://www.axi.com/int/live-account?promocode=4702887",
    },
    {
      src: bnr2,
      alt: "Slide 2",
      text: "Register",
      link: "https://www.bybit.com/en/dashboard/?ref=VG1ONR",
    },
    {
      src: bnr3,
      alt: "Slide 3",
      text: "Apply Now",
      link: "https://wap.redotpay.com/en/invite/?referralId=a94yk",
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setSlideIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Automatically change slide every 3 seconds
    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div className="slider-container">
      <div
        className="slider-wrapper"
        style={{
          transform: `translateX(-${slideIndex * 100}%)`,
        }}
      >
        {images.map((image, index) => (
          <div
            key={index}
            className={`slide ${slideIndex === index ? "active" : ""}`}
          >
            <img
              src={image.src}
              alt={image.alt}
              onClick={() => (window.location.href = image.link)} // Redirect on image click
            />
            <a
              href={image.link}
              className="banner-text" // Make the text clickable and redirect
              target="_blank" // Open link in a new tab
              rel="noopener noreferrer" // Security measure for external links
            >
              {image.text}
            </a>
          </div>
        ))}
      </div>
      <div className="slider-dots">
        {images.map((_, index) => (
          <span
            key={index}
            className={`dot ${slideIndex === index ? "active" : ""}`}
            onClick={() => setSlideIndex(index)}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default Slider;
