import React, { useState } from "react";
import "../assets/css/Portfolio.css"; // Create a separate CSS file for styles

const Portfolio = () => {
  const [activeTab, setActiveTab] = useState("usdm");
  const [activeSubTab, setActiveSubTab] = useState("open-positions");

  const showTab = (tab) => {
    setActiveTab(tab);
    setActiveSubTab("open-positions"); // Reset to open positions when tab changes
  };

  const showSubTab = (subTab) => {
    setActiveSubTab(subTab);
  };

  return (
    <div className="container">
      <div className="header">Trade</div>
      <div className="connect-exchange">
        <div>Connect Exchange</div>
        <button>Bind API</button>
      </div>
      <div className="tabs">
        <div
          className={`tab ${activeTab === "usdm" ? "active" : "inactive"}`}
          onClick={() => showTab("usdm")}
        >
          Futures
          <br />
          USD-M
        </div>
        <div
          className={`tab ${activeTab === "coinm" ? "active" : "inactive"}`}
          onClick={() => showTab("coinm")}
        >
          Futures
          <br />
          COIN-M
        </div>
      </div>
      <div className="sub-tabs">
        <div
          className={`sub-tab ${
            activeSubTab === "open-positions" ? "active" : ""
          }`}
          onClick={() => showSubTab("open-positions")}
        >
          Open Positions(0)
        </div>
        <div
          className={`sub-tab ${
            activeSubTab === "open-orders" ? "active" : ""
          }`}
          onClick={() => showSubTab("open-orders")}
        >
          Open Orders(0)
        </div>
        <div
          className={`sub-tab ${activeSubTab === "history" ? "active" : ""}`}
          onClick={() => showSubTab("history")}
        >
          History
        </div>
      </div>
      <div id={activeTab} className="content">
        {activeTab === "usdm" && (
          <div className="sub-content">
            {activeSubTab === "open-positions" && (
              <Table
                data={[
                  { position: "BTCUSD", amount: 0.5, price: "$50,000" },
                  { position: "ETHUSD", amount: 1.0, price: "$3,000" },
                ]}
              />
            )}
            {activeSubTab === "open-orders" && (
              <Table
                data={[
                  { position: "BTCUSD", amount: 0.2, price: "$48,000" },
                  { position: "ETHUSD", amount: 0.5, price: "$2,800" },
                ]}
              />
            )}
            {activeSubTab === "history" && (
              <Table
                data={[
                  { position: "BTCUSD", amount: 0.3, price: "$49,000" },
                  { position: "ETHUSD", amount: 0.7, price: "$2,900" },
                ]}
              />
            )}
          </div>
        )}
        {activeTab === "coinm" && (
          <div className="sub-content">
            {activeSubTab === "open-positions" && (
              <Table
                data={[
                  { position: "BTCUSD", amount: 0.3, price: "$51,000" },
                  { position: "ETHUSD", amount: 0.8, price: "$3,100" },
                ]}
              />
            )}
            {activeSubTab === "open-orders" && (
              <Table
                data={[
                  { position: "BTCUSD", amount: 0.1, price: "$50,500" },
                  { position: "ETHUSD", amount: 0.4, price: "$3,050" },
                ]}
              />
            )}
            {activeSubTab === "history" && (
              <Table
                data={[
                  { position: "BTCUSD", amount: 0.2, price: "$50,200" },
                  { position: "ETHUSD", amount: 0.6, price: "$3,020" },
                ]}
              />
            )}
          </div>
        )}
      </div>
      {/* <Footer /> */}
      {/* <div class Name="floating-button">
        <i className="fas fa-headset"></i>
      </div> */}
    </div>
  );
};

const Table = ({ data }) => (
  <table className="data-table">
    <thead>
      <tr>
        <th>Position</th>
        <th>Amount</th>
        <th>Price</th>
      </tr>
    </thead>
    <tbody>
      {data.map((item, index) => (
        <tr key={index}>
          <td>{item.position}</td>
          <td>{item.amount}</td>
          <td>{item.price}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

// const Footer = () => (
//   <div className="footer">
//     <div className="icon">
//       <i className="fas fa-home"></i>
//       <p>Home</p>
//     </div>
//     <div className="icon">
//       <i className="fas fa-chart-line"></i>
//       <p>Portfolio</p>
//     </div>
//     <div className="icon">
//       <i className="fas fa-rocket"></i>
//       <p>Algo</p>
//     </div>
//     <div className="icon">
//       <i className="fas fa-wallet"></i>
//       <p>Wallet</p>
//     </div>
//     <div className="icon">
//       <i className="fas fa-user"></i>
//       <p>Profile</p>
//     </div>
//   </div>
// );

export default Portfolio;
