import React from "react";
import { Link } from "react-router-dom";
import "../App.css"; // Assuming you have a CSS file for styles
// import "../assets/css/Home.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faUserCircle,
  faBullhorn,
  faBars,
  faCircle,
  faHeart,
  faHome,
  faBriefcase,
  faRobot,
  faWallet,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  return (
    <div>
      <div>
        <ul className="nav">
          <div className="nav-item">
            <li>
              <FontAwesomeIcon icon={faHome} />
              <Link to="/">Home</Link>
            </li>
          </div>
          <div className="nav-item">
            <li>
              <FontAwesomeIcon icon={faBriefcase} />
              <Link to="/portfolio">Portfolio</Link>
            </li>
          </div>
          <div className="nav-item">
            <li>
              <FontAwesomeIcon icon={faRobot} />
              <Link to="/algo">Algo</Link>
            </li>
          </div>
          <div className="nav-item">
            <li>
              <FontAwesomeIcon icon={faWallet} />
              <Link to="/wallet">Wallet</Link>
            </li>
          </div>
          <div className="nav-item">
            <li>
              <FontAwesomeIcon icon={faUser} />
              <Link to="/profile">Profile</Link>
            </li>
          </div>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
