import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import the toast styles
import "../App.css"; // Assuming you have a CSS file for styles
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Slider from "../components/slider";
import {
  faBell,
  faUserCircle,
  faBullhorn,
  faBars,
  faCircle,
  faHeart,
  faHome,
  faBriefcase,
  faRobot,
  faWallet,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

const Link1 = "https://www.example.com";
const Link2 = "https://www.example.com";
const Link3 = "https://www.example.com";
const handleRedirect1 = () => {
  window.location.href = Link1; // Redirect to the external link
};

const handleRedirect2 = () => {
  window.location.href = Link2; // Redirect to the external link
};

const handleRedirect3 = () => {
  window.location.href = Link3; // Redirect to the external link
};

const Home = () => {
  const [sessionValid, setSessionValid] = useState(false);
  const [slideIndex, setSlideIndex] = useState(0);
  const navigate = useNavigate(); // For programmatic navigation

  // useEffect(() => {
  //   const token = sessionStorage.getItem("token"); // Get token from sessionStorage

  //   if (token) {
  //     setSessionValid(true); // Session exists, user is valid
  //   } else {
  //     setSessionValid(false); // No token in sessionStorage
  //     // Show toast and redirect to login page if no session token
  //     toast.error("Session expired, please log in again.");
  //     setTimeout(() => {
  //       navigate("/login"); // Redirect to login page after a delay
  //     }, 3000); // Delay redirection to allow toast to show
  //   }
  // }, [navigate]);

  useEffect(() => {
    const interval = setInterval(() => {
      setSlideIndex((prevIndex) => (prevIndex + 1) % 3);
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="container">
      <div style={{ display: "none" }}>
        {sessionValid ? (
          <h1>Welcome, you are logged in!</h1>
        ) : (
          <h1>Please log in to access this page.</h1>
        )}
      </div>
      <Slider />
      {/* <div className="banner">
        <div className="slider">
          <div
            className="slides"
            style={{ transform: `translateX(-${slideIndex * 100}%)` }}
          >
            <img
              style={{ cursor: "pointer" }}
              onClick={handleRedirect1}
              alt="Automated Trading with Expert Algorithms 1"
              src={bnr1}
              // src="https://storage.googleapis.com/a1aa/image/2MqxbdEpSYqUOJ7yQpJ8FCWTXmjMuROBbrYhHT8E2DE0s88E.jpg"
            />
            <img
              style={{ cursor: "pointer" }}
              onClick={handleRedirect2}
              alt="Automated Trading with Expert Algorithms 2"
              src={bnr2}
              // src="https://storage.googleapis.com/a1aa/image/7dZO6hkh5x7vDxfZpg1dYgy5iqlTtu0ubmveKxYKa5ZRzyzTA.jpg"
            />
            <img
              style={{ cursor: "pointer" }}
              onClick={handleRedirect3}
              alt="Automated Trading with Expert Algorithms 3"
              src={bnr3}
              // src="https://storage.googleapis.com/a1aa/image/8VQ10x3M9wYlPtBGd4wjwiCMDLgnKv8B11bqxbOMe3WnZ55JA.jpg"
            />
          </div>
        </div>
        <div className="dots">
          <span className={`dot ${slideIndex === 0 ? "active" : ""}`}></span>
          <span className={`dot ${slideIndex === 1 ? "active" : ""}`}></span>
          <span className={`dot ${slideIndex === 2 ? "active" : ""}`}></span>
        </div>
      </div> */}
      <div className="announcements">
        <FontAwesomeIcon icon={faBullhorn} />
        <span>Announcements</span>
        <FontAwesomeIcon icon={faBars} className="menu-icon" />
      </div>
      <div className="grid">
        <div className="grid-item">
          <img
            alt="API Bind Icon"
            height="50"
            src="https://storage.googleapis.com/a1aa/image/7dZO6hkh5x7vDxfZpg1dYgy5iqlTtu0ubmveKxYKa5ZRzyzTA.jpg"
            width="50"
          />
          <p>API Bind</p>
        </div>
        <div className="grid-item">
          <img
            alt="Trade Icon"
            height="50"
            src="https://storage.googleapis.com/a1aa/image/8VQ10x3M9wYlPtBGd4wjwiCMDLgnKv8B11bqxbOMe3WnZ55JA.jpg"
            width="50"
          />
          <p>Trade</p>
        </div>
        <div className="grid-item">
          <img
            alt="Invite Friend Icon"
            height="50"
            src="https://storage.googleapis.com/a1aa/image/NhtGE0fbt0zzTy7rSPhT5FgQQDjuDSeedyPFzMPhNoVeMLPPB.jpg"
            width="50"
          />
          <p>Invite friend</p>
        </div>
        <div className="grid-item">
          <img
            alt="Asset Icon"
            height="50"
            src="https://storage.googleapis.com/a1aa/image/CZH7GP3tIIKHNpEjrrUUgrsv4PLsIwF74o9fLqoeXWENzyzTA.jpg"
            width="50"
          />
          <p>Asset</p>
        </div>
        <div className="grid-item">
          <img
            alt="Deposit Icon"
            height="50"
            src="https://storage.googleapis.com/a1aa/image/K71XCD2pUuLxLh39p73ZggjONn5p9PL9JF3Ga6ITeyMpZ55JA.jpg"
            width="50"
          />
          <p>Deposit</p>
        </div>
        <div className="grid-item">
          <img
            alt="Activation Icon"
            height="50"
            src="https://storage.googleapis.com/a1aa/image/7VibQ6DZde0mZKbiDisunyH8pemrmZy0jSBZMYfCK5TXmlnnA.jpg"
            width="50"
          />
          <p>Activation</p>
        </div>
      </div>
      <div className="bot-status">
        <div className="status-header">
          <div className="status">
            <span>Bot Status</span>
            <FontAwesomeIcon icon={faCircle} />
            <span>Inactive</span>
          </div>
        </div>
        <div className="status-content">
          <p>Your bot is inactive. Activate your UnTrade Bot!</p>
          <button className="activate-btn">Activate Bot</button>
        </div>
      </div>
      <div className="connect-exchange">
        <p>Connect Exchange</p>
        <button className="bind-api-btn">Bind API</button>
      </div>
      <div className="footer">
        <p>Your Road To Financial Freedom!</p>
        <p className="crafted">
          Crafted with <FontAwesomeIcon icon={faHeart} /> by Zelta Automations
        </p>
      </div>

      {/* Toast container to display messages */}
      <ToastContainer />
    </div>
  );
};

export default Home;
