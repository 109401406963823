import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faUserCircle } from "@fortawesome/free-solid-svg-icons";

const Header = () => {
  return (
    <header>
      <div className="header">
        <img
          alt="Logo"
          height="30"
          src="https://storage.googleapis.com/a1aa/image/qj5d1NyiWf2VHa6FrwMGiwUeLbLZ7HMDHLPFmZIV5aATzyzTA.jpg"
          width="100"
        />
        <div className="icons">
          <FontAwesomeIcon icon={faBell} />
          <FontAwesomeIcon icon={faUserCircle} />
        </div>
      </div>
    </header>
  );
};

export default Header;
