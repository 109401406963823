import React from "react";
import "../assets/css/Profile.css"; // Assuming you have a CSS file for styles
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExchangeAlt,
  faUserFriends,
  faUsers,
  faBullhorn,
  faQuestionCircle,
  faShieldAlt,
  faFileAlt,
  faHeadset,
  faCog,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const Profile = () => {
  return (
    <div className="container">
      <header className="header">
        <img
          src="https://storage.googleapis.com/a1aa/image/dxEx7FgeBcQbBSZeeyZiqZSBM2buBwTnOiFi10ARv86aM8onA.jpg"
          alt="User profile picture"
        />
        <div className="info">
          <h2>mohitsiss972590</h2>
          <p>UUID: ------</p>
          <p>mohitsiss97@gmail.com</p>
        </div>
      </header>
      <div className="activate-section">
        Activate your account
        <button>Activate</button>
      </div>
      <div className="progress-bar">
        <div>
          <div className="step completed">1</div>
          <p>
            Sign Up
            <br />
            Completed
          </p>
        </div>
        <div>
          <div className="step active">2</div>
          <p>Subscribe</p>
        </div>
        <div>
          <div className="step">3</div>
          <p>API bind</p>
        </div>
        <div>
          <div className="step">4</div>
          <p>
            Follow
            <br />
            Strategy
          </p>
        </div>
      </div>
      <div className="menu">
        <div className="menu-item">
          <FontAwesomeIcon icon={faExchangeAlt} />
          <span>
            <Link to="/ExchangeApi"> Exchange API’s </Link>
          </span>
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
        <div className="menu-item">
          <FontAwesomeIcon icon={faUserFriends} />
          <span>Referral Details</span>
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
        <div className="menu-item">
          <FontAwesomeIcon icon={faUsers} />
          <span>Your Team</span>
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
        <div className="menu-item">
          <FontAwesomeIcon icon={faBullhorn} />
          <span>Marketing Content</span>
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
        <div className="menu-item">
          <FontAwesomeIcon icon={faQuestionCircle} />
          <span>FAQs</span>
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
        <div className="menu-item">
          <FontAwesomeIcon icon={faShieldAlt} />
          <span>Security</span>
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
        <div className="menu-item">
          <FontAwesomeIcon icon={faFileAlt} />
          <span>Policy</span>
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
        <div className="menu-item">
          <FontAwesomeIcon icon={faUsers} />
          <span>Community</span>
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
        <div className="menu-item">
          <FontAwesomeIcon icon={faHeadset} />
          <span>Support</span>
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
        <div className="menu-item">
          <FontAwesomeIcon icon={faCog} />
          <span>System Settings</span>
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
      </div>
    </div>
  );
};

export default Profile;
