// src/components/Login.js
import React, { useState } from "react";
import axios from "axios";
import "../assets/css/Login.css";
import { useNavigate } from "react-router-dom"; // For navigation after successful login

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate(); // Use the history hook to redirect

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post("http://localhost:5000/login", {
        username,
        password,
      });

      // Save the token to localStorage or sessionStorage
      sessionStorage.setItem("token", response.data.token);
      navigate("/home");
      console.log("Logged in successfully:", response.data.token);
      // Redirect the user to the home page (or any other page)

      // Redirect user to a protected route or dashboard after login
      // For example: window.location.href = '/dashboard';
    } catch (err) {
      setError(err.response ? err.response.data.message : "Server error");
    }
  };

  return (
    <div className="auth-container">
      <div className="auth-box">
        <h2>Login</h2>
        {error && <p className="error">{error}</p>}
        <form onSubmit={handleLogin}>
          <div className="input-group">
            <label>Username</label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <label>Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="btn">
            Login
          </button>
        </form>
        <p>
          Don't have an account? <a href="/register">Register</a>
        </p>
      </div>
    </div>
  );
};

export default Login;
